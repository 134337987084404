<template>
  <div class="row h-100">
    <div
      :class="bgClass"
      class="col hide-mobile"
    />
    <div class="col min-h-100">
      <div class="authincation h-100">
        <div class="container h-100">
          <div class="row justify-content-center align-items-center h-100-scroll">
            <div class="col-md-10">
              <div class="authincation-content">
                <div class="row no-gutters">
                  <div class="col-xl-12">
                    <div class="auth-form">
                      <div class="d-flex justify-content-center mb-3">
                        <div class="rounded-logo" />
                      </div>
                      <template v-if="cadastroCandidatoStore.finished" />
                      <template v-else>
                        <h4 class="text-center mb-4">
                          Cadastre-se
                        </h4>
                        <Navigation v-if="cadastroCandidatoStore.currentStep < 2" />
                        <v-form
                          ref="form"
                          @submit.prevent="cadastroCandidatoStore.nextStep"
                          v-model="cadastroCandidatoStore.valid[cadastroCandidatoStore.currentStep]"
                        >
                          <CustomTransition>
                            <component
                              :is="
                                cadastroCandidatoStore.steps[cadastroCandidatoStore.currentStep].component
                              "
                            />
                          </CustomTransition>
                          <div class="d-flex justify-content-center">
                            <termos-uso 
                            />
                          </div>
                          <Step
                            v-for="step in cadastroCandidatoStore.steps"
                            :key="step.id"
                            :step="step"
                            :step-count="cadastroCandidatoStore.steps.length"
                            :current-step="cadastroCandidatoStore.currentStep"
                          />
                        
                        </v-form>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TermosUso from "@/components/layout/TermosUso.vue";
import { useRoute } from 'vue-router'
import CustomTransition from '@/components/layout/CustomTransition.vue'
import Navigation from '@/components/wizard/Navigation.vue'
import Step from '@/components/wizard/Step.vue'
import { onActivated, onBeforeUpdate, ref, onMounted } from 'vue'
import { useCadastroCandidatoStore } from '@/stores/cadastroCandidatoStore'
import { Profile } from '@/enums/profileEnum'

const cadastroCandidatoStore = useCadastroCandidatoStore()
const route = useRoute()

const form = ref()

onActivated(() => {
})

onBeforeUpdate(() => {
  if (cadastroCandidatoStore.completedSteps.find(step => step === cadastroCandidatoStore.currentStep) != undefined) {
    form.value.validate()
  }
})
onMounted(() => {
  if (route.name === 'cadastro-recrutador') {
    cadastroCandidatoStore.candidato.profile_id = Profile.RECRUTADOR
  }
})

const bgClass =
  route.path == '/cadastro-recrutador' ? 'bg-login-recrutador' : 'bg-cadastro'
</script>
