import { ICidadeApi } from '@/interfaces/CidadeInterface'
import { IEnderecoApi, IRegiaoApi } from '@/interfaces/EnderecoInterface'
import { IEstado, IEstadoApi } from '@/interfaces/EstadoInterface'
import API from '@/services/http-common'
import { AxiosPromise, AxiosResponse } from 'axios'

class EnderecoService {
    getFromCEP(cep: String): Promise<AxiosResponse<IEnderecoApi>> {
        return API.get<IEnderecoApi>(`/endereco/cep/${cep}`)
    }

    getEstados(): Promise<AxiosResponse<IEstadoApi>> {
        return API.get<IEstadoApi>(`/endereco/estados`)
    }

    getCidades(estado_id: number): Promise<AxiosResponse<ICidadeApi>> {
        return API.get<ICidadeApi>(`/endereco/cidades/${estado_id}`)
    }

    getRegioes(): Promise<AxiosResponse<IRegiaoApi>> {
        return API.get<IRegiaoApi>('/endereco/regioes')
    }
}

export default new EnderecoService()