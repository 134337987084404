import { AxiosResponse } from "axios";
import API from "./http-common";
import { IValidateSimpleRequest, IValidationApi, IValidationUniqueRequest } from "@/interfaces/ValidationInterface";

class ValidationService {
    uniqueApi(data: IValidationUniqueRequest): Promise<AxiosResponse<IValidationApi>> {
        return API.post<IValidationApi>('validate/unique', data)
    }

    cnpj(data: IValidateSimpleRequest): Promise<AxiosResponse<IValidationApi>> {
        return API.post<IValidationApi>('validate/cnpj', data)
    }

    cpf(data: IValidateSimpleRequest): Promise<AxiosResponse<IValidationApi>> {
        return API.post<IValidationApi>('validate/cpf', data)
    }
}

export default new ValidationService();