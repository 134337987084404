<template>
  <div class="row">
    <div class="col-sm-12 col-md-4">
      <InputMasked
        :label="editando ? 'CEP' : ''"
        :readonly="!editando"
        input-mask="##.###-###"
        placeholder="CEP"
        v-model="endereco.endereco.cep.cep"
        @update:model-value="consultaEndereco"
        :loading="consultandoApi"
        :rules="[required()]"
      />
    </div>
    <div class="col-sm-12 col-md-8">
      <v-text-field
        :label="editando ? 'Logradouro' : ''"
        placeholder="Logradouro"
        v-model="endereco.endereco.logradouro.nome"
        readonly
        :rules="[required()]"
      />
    </div>
    <div class="col-sm-4">
      <v-text-field
        :label="editando ? 'Número' : ''"
        :readonly="!editando"
        placeholder="Número"
        v-model="endereco.numero"
        @update:model-value="$emit('updateEndereco', endereco)"
        required
        :rules="[required()]"
      />
    </div>
    <div class="col-sm-8">
      <v-text-field
        :label="editando ? 'Bairro' : ''"
        placeholder="Bairro"
        v-model="endereco.endereco.bairro.nome"
        readonly
        :rules="[required()]"
      />
    </div>
    <div class="col-sm-9">
      <v-text-field
        :label="editando ? 'Cidade' : ''"
        placeholder="Cidade"
        v-model="endereco.endereco.cidade.nome"
        readonly
        :rules="[required()]"
      />
    </div>
    <div class="col-sm-3">
      <v-text-field
        :label="editando ? 'UF' : ''"
        placeholder="UF"
        v-model="endereco.endereco.estado.sigla"
        readonly
        :rules="[required()]"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-8">
      <v-text-field
        :label="editando ? 'Complemento' : ''"
        :readonly="!editando"
        placeholder="Complemento"
        v-model="endereco.complemento"
        @update:model-value="$emit('updateEndereco', endereco)"
      />
    </div>
    <div
      class="col-sm-12 col-md-4"
      v-if="regiaoHas == true"
    >
      <v-autocomplete
        placeholder="Região"
        :label="editando ? 'Região' : ''"
        :readonly="!editando"
        :items="regioes"
        item-title="nome"
        item-value="id"
        v-model="endereco.regiao_id"
        @update:model-value="$emit('updateEndereco', endereco)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import enderecoService from '@/services/enderecoService'
import InputMasked from '@/components/form/InputMasked.vue'
import { onBeforeMount, ref, Ref } from 'vue'
import { IRegiao, IUserEndereco } from '@/interfaces/EnderecoInterface'
import Swal from 'sweetalert2'
import { required } from '@/validation/rules'

const consultandoApi = ref(false)
const regioes = ref<Array<IRegiao>>([])
const props = withDefaults(
  defineProps<{
    editando?: boolean,
    userEndereco: IUserEndereco,
    regiaoHas:boolean
  }>(),
  {
    editando: true,
    regiaoHas: true
  }
)

const cancelarAlteracao = () => {
  endereco.value = props.userEndereco || {} as IUserEndereco
}

defineExpose({
  cancelarAlteracao
})

const endereco: Ref<IUserEndereco> = ref<IUserEndereco>({} as IUserEndereco)

onBeforeMount(async () => {
  if (props.regiaoHas) {
    getRegioes()
  }
  if (props.userEndereco != null) {
    endereco.value = props.userEndereco
  } else {
    endereco.value = {} as IUserEndereco
  }
})
const emit = defineEmits<{(emit: 'updateEndereco', value: IUserEndereco): void }>()

async function getRegioes (): Promise<void> {
  regioes.value = (await enderecoService.getRegioes()).data.data
}

async function consultaEndereco (value: String): Promise<void> {
  const cepConsultado = value.replace(/\D/gim, '')
  if (cepConsultado.length === 8) {
    try {
      consultandoApi.value = true
      const response = await enderecoService.getFromCEP(cepConsultado)
      endereco.value.endereco = response.data.data
      consultandoApi.value = false
      if (response.status === 200) {
        emit('updateEndereco', endereco.value)
      }
    } catch (response) {
      consultandoApi.value = false
      Swal.fire({
        title: 'Oops...',
        text: 'Erro ao consultar endereço',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#FF3333'
      })
    }
  }
}
</script>
