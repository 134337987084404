<template>
    <li :class="{ 'active': active, 'complete': completed }">
        <div class="step">
            <i :class="step.icon"></i>
        </div>
        <div class="caption hidden-xs hidden-sm">
            <span v-text="step.title"></span>
        </div>
    </li>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { IStep } from '@/interfaces/StepInterface';
import { useCadastroCandidatoStore } from '@/stores/cadastroCandidatoStore';

const cadastroCandidatoStore = useCadastroCandidatoStore()

interface Props {
    step: IStep
}
const props = defineProps<Props>()

const active = computed(() => props.step.id === cadastroCandidatoStore.currentStep)
const completed = computed(() => cadastroCandidatoStore.currentStep > props.step.id)
</script>
