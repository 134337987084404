<template>
  <v-text-field
    :model-value="mask.masked(modelValue)"
    @update:model-value="updateMaskedModel"
    :loading="loading"
  />
</template>

<script setup lang="ts">
import { Mask } from 'maska'

type updatedCallbackType = (value: string) => any | undefined;

const props = withDefaults(
  defineProps<{
    modelValue: string;
    inputMask?: string;
    type?: string;
    loading?: boolean;
    updatedCallback?: updatedCallbackType;
  }>(),
  {
    modelValue: '',
    inputMask: '',
    type: 'text',
    loading: false,
    updatedCallback: undefined
  }
)

const mask = new Mask({ mask: props.inputMask })

const emit = defineEmits<{(emit: 'update:modelValue', value: String): void;}>()

function updateMaskedModel (value: string) {
  if (props.updatedCallback) {
    props.updatedCallback(mask.unmasked(value))
  }

  emit('update:modelValue', mask.masked(value))
}
</script>
