import { Profile } from "@/enums/profileEnum";
import { IBairro, ICep, ICidade, IEndereco, IEnderecoApi, IEstado, ILogradouro, IUserEndereco } from "@/interfaces/EnderecoInterface";
import { IStep } from "@/interfaces/StepInterface";
import { IUserCreate } from "@/interfaces/UserInterface";
import EnderecoService from "@/services/enderecoService";
import userService from "@/services/userService";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { markRaw, defineAsyncComponent, Ref, ref, computed } from "vue";

const newUser = (): IUserCreate => {
    let user = {} as IUserCreate
    user.profile_id = Profile.CANDIDATO
    user.endereco = {
        endereco: {
            cep: {} as ICep,
            logradouro: {} as ILogradouro,
            bairro: {} as IBairro,
            cidade: {} as ICidade,
            estado: {} as IEstado
        } as IEndereco,
    } as IUserEndereco

    return user
}

export type CadastroCandidatotStore = ReturnType<typeof useCadastroCandidatoStore>
export const useCadastroCandidatoStore = defineStore('cadastroCandidato', () => {
    const currentStep: Ref<number> = ref(0)
    const steps: Ref<Array<IStep>> = ref([
        {
            id: 0,
            title: 'Identificação',
            icon: 'fa fa-user',
            component: markRaw(defineAsyncComponent(() => import('@/views/candidato/steps/Identificacao.vue')))
        },
        {
            id: 1,
            title: 'Endereço',
            icon: 'fa-solid fa-location-dot',
            component: markRaw(defineAsyncComponent(() => import('@/views/candidato/steps/Endereco.vue')))
        }
    ])

    const candidato: Ref<IUserCreate> = ref(newUser())
    const submited: Ref<boolean> = ref(false)
    const finished: Ref<boolean> = ref(false)
    const valid: Ref<Array<boolean>> = ref([
        false,
        false
    ])
    const completedSteps: Ref<Array<number>> = ref([])

    const getCepSomenteNumeros = computed(() => candidato.value.endereco.endereco.cep.cep.replace(/\D/gim, ''))

    function nextStep(): void {
        completeStep()
        currentStep.value++
    }

    function prevStep(): void {
        completeStep()
        currentStep.value--
    }

    function completeStep(): void {
        if (completedSteps.value.find(step => step === currentStep.value) === undefined) {
            completedSteps.value.push(currentStep.value)
        }
    }
    
    function whatsappUrl(): string {
        if (candidato.value.uuid) {
            const queryParams = new URLSearchParams({ "text": `CONFIRMAR: ${candidato.value.uuid}` }).toString()
            const whatsappNumber = import.meta.env.VITE_WHATSAPP_NUMBER
            return `https://wa.me/${whatsappNumber}?${queryParams}`
        } else {
            return '#'
        }
    }

    function consultarEndereco(cep: string | undefined): void {
        if (cep && getCepSomenteNumeros.value.length === 8) {
            EnderecoService.getFromCEP(getCepSomenteNumeros.value).then((response: AxiosResponse<IEnderecoApi>) => {
                candidato.value.endereco.endereco = response.data.data
            })
        }
    }

    function save(this: CadastroCandidatotStore): void {
        userService.create(candidato.value).then(response => { 
            candidato.value.uuid = response.data.data.uuid
            this.router.push({
                name: "finalizar-cadastro"
            })

        })
    }

    return {
        currentStep,
        steps,
        candidato,
        submited,
        finished,
        valid,
        completedSteps,
        nextStep,
        prevStep,
        completeStep,
        whatsappUrl,
        consultarEndereco,
        save,
    }
})