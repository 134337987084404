<template>
  <div class="step-wrapper" :class="{ active: active }">
    <div class="d-flex justify-content-between">
      <button type="button" class="btn btn-secondary" style="color: white" @click="cadastroCandidatoStore.prevStep"
        :disabled="firstStep">
        Anterior
      </button>
      <button type="button" class="btn btn-primary" style="color: white" @click="cadastroCandidatoStore.nextStep"
        :disabled="!cadastroCandidatoStore.valid[cadastroCandidatoStore.currentStep]" v-if="!lastStep">
        Próximo
      </button>
      <button type="button" class="btn btn-primary" @click="cadastroCandidatoStore.save" style="color: white"
        :disabled="!cadastroCandidatoStore.valid[cadastroCandidatoStore.currentStep]" v-else>
        Salvar
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { IStep } from "@/interfaces/StepInterface";
import { useCadastroCandidatoStore } from "@/stores/cadastroCandidatoStore";

const cadastroCandidatoStore = useCadastroCandidatoStore()

interface Props {
  step: IStep;
  stepCount: number;
}
const props = defineProps<Props>();

const active = computed<boolean>(
  () => props.step.id === cadastroCandidatoStore.currentStep
);
const firstStep = computed<boolean>(() => cadastroCandidatoStore.currentStep === 0);
const lastStep = computed<boolean>(
  () => cadastroCandidatoStore.currentStep === props.stepCount - 1
);
</script>
