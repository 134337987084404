<template>
  <li>
    <Endereco
      :editando="true"
      :user-endereco="store.candidato.endereco"
      :regiaoHas="false"
    />
    <div
      class="row"
      v-if="hasTermoCondicao"
    >
      <div class="col">
        <v-checkbox
          label="Aceito os termos e condições"
          color="primary"
          v-model="store.candidato.optin"
          :rules="[required()]"
        />
        <termos-uso />
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import { useCadastroCandidatoStore } from '@/stores/cadastroCandidatoStore'
import { required } from '@/validation/rules'
import TermosUso from '@/components/layout/TermosUso.vue'
import Endereco from '@/components/layout/Endereco.vue'

const store = useCadastroCandidatoStore()

withDefaults(
  defineProps<{
    hasTermoCondicao?: boolean;
  }>(),
  {
    hasTermoCondicao: true
  }
)

</script>
