<template>
  <ol class="step-indicator">
    <!-- <li v-for="step in wizardStore.steps" :key="step.id" :step="step" :currentstep="wizardStore.currentStep"> -->
    <NavigationStep
      v-for="step in cadastroCadidatoStore.steps"
      :key="step.id"
      :step="step"
    />
    <!-- </li> -->
  </ol>
</template>

<script setup lang="ts">
import { useCadastroCandidatoStore } from "@/stores/cadastroCandidatoStore";
import NavigationStep from "./NavigationStep.vue";

const cadastroCadidatoStore = useCadastroCandidatoStore()
</script>
