<template>
  <div>
    <v-text-field
      label="Nome"
      v-model="cadastroCandidatoStore.candidato.first_name"
      :rules="[
        required(),
        sizeBetween(3, 100)
      ]"
    />
    <v-text-field
      label="Sobrenome"
      v-model="cadastroCandidatoStore.candidato.last_name"
      :rules="[
        required(),
        sizeBetween(3, 100)
      ]"
    />
    <v-text-field
      type="email"
      label="E-mail"
      v-model="cadastroCandidatoStore.candidato.email"
      :rules="[
        required(),
        uniqueApi(validationService.uniqueApi, { field: 'email', table: 'users', keyColumn: 'id', keyValue: '3' }, 'Endereço de e-mail já cadastrado'),
        email(),
      ]"
    />
  </div>
</template>

<script setup lang="ts">
import { required, uniqueApi, email, sizeBetween } from '@/validation/rules'
import validationService from '@/services/validationService'
import { useCadastroCandidatoStore } from '@/stores/cadastroCandidatoStore'

const cadastroCandidatoStore = useCadastroCandidatoStore()

// cadastroCandidatoStore.candidato.profile.id = route.path == "/cadastro-recrutador" ? Profile.RECRUTADOR : Profile.CANDIDATO;
</script>
